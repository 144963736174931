import axiosInstance from "../appAxiosInstance";
import { appEndpoints } from "../appEndpoints";

export const appGetSettings = () =>
  axiosInstance.get(appEndpoints.SETTINGS.GET);
export const appToggleTechnicalWorks = (isTechnicalWorks) =>
  axiosInstance.post(appEndpoints.SETTINGS.TOGGLE_TECHNICAL_WORKS, {
    technical_work: isTechnicalWorks,
  });

export const appGetAllDeposits = () =>
  axiosInstance.get(appEndpoints.SETTINGS.GET_ALL_DEPOSITS);

export const appDepositPercentOrCommissionChange = (depositId, data) =>
  axiosInstance.patch(
    `${appEndpoints.SETTINGS.DEPOSIT_PERCENT_OR_COMMISSION_CHANGE}/${depositId}`,
    data
  );

export const appGetIpAddress = (depositType) =>
  axiosInstance.get(`${appEndpoints.SETTINGS.IP_ADDRESS}/${depositType}`);
