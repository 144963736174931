import { DatePicker } from "@mui/x-date-pickers";

const AppDateInput = ({ sx, label, value, disabled, handleChange }) => {
  return (
    <DatePicker
      sx={sx}
      label={label ?? ""}
      value={new Date(value)}
      disabled={disabled}
      onChange={handleChange}
    />
  );
};

export default AppDateInput;
