import axiosInstance from "../appAxiosInstance";
import { appEndpoints } from "../appEndpoints";

export const appGetUserById = (id) =>
  axiosInstance.get(`${appEndpoints.USER.GET}/${id}`);

export const appSendDepositDocument = (formData, contractId, userId) =>
  axiosInstance.post(
    `${appEndpoints.USER.SEND_DEPOSIT_FILE}/${contractId}/${userId}`,
    formData
  );

export const appUserContractUploadDocument = (formData, contractId, userId) =>
  axiosInstance.post(
    `${appEndpoints.USER.CONTRACT_UPLOAD}/${contractId}/${userId}`,
    formData
  );
export const appUserContractUploadReOpenDocument = (
  formData,
  contractId,
  userId
) =>
  axiosInstance.post(
    `${appEndpoints.USER.CONTRACT_UPLOAD_RE_OPEN}/${contractId}/${userId}`,
    formData
  );
export const appUserContractUploadUpdateDocument = (
  formData,
  contractId,
  userId
) =>
  axiosInstance.post(
    `${appEndpoints.USER.CONTRACT_UPLOAD_UPDATE}/${contractId}/${userId}`,
    formData
  );

export const appUserContractCreatedStatusSetNotPay = (contractId) =>
  axiosInstance.patch(
    `${appEndpoints.USER.CONTRACT_CHECK_NOT_PAY}/${contractId}`
  );

export const appUserContractDocumentSigningSetNotPay = (contractId) =>
  axiosInstance.patch(`${appEndpoints.USER.CONTRACT_CHECK}/${contractId}`, {
    check: false,
  });
export const appUserContractDocumentSigningSetPay = (contractId) =>
  axiosInstance.patch(`${appEndpoints.USER.CONTRACT_CHECK}/${contractId}`, {
    check: true,
  });

export const appUserContractDocumentSigningReOpenSetNotPay = (contractId) =>
  axiosInstance.patch(
    `${appEndpoints.USER.CONTRACT_CHECK_RE_OPEN}/${contractId}`,
    { check: false }
  );
export const appUserContractDocumentSigningReOpenSetPay = (contractId) =>
  axiosInstance.patch(
    `${appEndpoints.USER.CONTRACT_CHECK_RE_OPEN}/${contractId}`,
    { check: true }
  );

export const appUserContractDocumentSigningUpdateSetNotPay = (contractId) =>
  axiosInstance.patch(
    `${appEndpoints.USER.CONTRACT_CHECK_UPDATE}/${contractId}`,
    { check: false }
  );
export const appUserContractDocumentSigningUpdateSetPay = (contractId) =>
  axiosInstance.patch(
    `${appEndpoints.USER.CONTRACT_CHECK_UPDATE}/${contractId}`,
    { check: true }
  );

export const appDocumentsVerify = (userId, verify, message) =>
  axiosInstance.post(`${appEndpoints.USER.DOCUMENTS_VERIFY}/${userId}`, {
    verify,
    message,
  });

export const appDepositTitleChange = (contractId, title) =>
  axiosInstance.patch(
    `${appEndpoints.USER.CHANGE_CONTRACT_TITLE}/${contractId}`,
    {
      title,
    }
  );

export const appDepositRateChange = (id, rate) =>
  axiosInstance.post(`${appEndpoints.USER_DEPOSITS.CHANGE_RATE}/${id}`, {
    current_interest_rate: rate,
  });

// не изменяет статус контракта
export const appUserDocUpdate = (contractId, userId, formData) =>
  axiosInstance.patch(
    `${appEndpoints.USER.CONTRACT_UPDATE}/${contractId}/${userId}`,
    formData
  );

// только для Fortis и Crypto
export const appNewDepositCreate = (
  userId,
  type,
  amount_start,
  currency,
  term,
  current_interest_rate,
  start_date
) =>
  axiosInstance.post(`${appEndpoints.USER_DEPOSITS.CREATE_DEPOSIT}/${userId}`, {
    type,
    amount_start,
    currency,
    term,
    current_interest_rate,
    start_date,
  });

// только для Prime
export const appNewPrimeDepositCreate = (
  userId,
  platform,
  api_key,
  secret_key
) =>
  axiosInstance.post(
    `${appEndpoints.USER_DEPOSITS.CREATE_PRIME_DEPOSIT}/${userId}`,
    {
      platform,
      api_key,
      secret_key,
    }
  );

export const appPrimeCommissionVisibilityUpdate = (depositId, pay_commission) =>
  axiosInstance.patch(
    `${appEndpoints.USER_DEPOSITS.UPDATE_PAY_COMMISSION_PRIME_DEPOSIT}/${depositId}`,
    {
      pay_commission,
    }
  );

export const appPrimeOnlySpotUpdate = (depositId, isOnlySpot) =>
  axiosInstance.patch(
    `${appEndpoints.USER_DEPOSITS.UPDATE_ONLY_SPOT_PRIME_DEPOSIT}/${depositId}`,
    {
      isOnlySpot,
    }
  );

export const appPrimeStartAmountUpdate = (depositId, amount_start) =>
  axiosInstance.patch(
    `${appEndpoints.USER_DEPOSITS.UPDATE_AMOUNT_PRIME_DEPOSIT}/${depositId}`,
    {
      amount_start,
    }
  );

export const appPrimeKeysUpdate = (depositId, apiKey, secretKey) =>
  axiosInstance.patch(
    `${appEndpoints.USER_DEPOSITS.UPDATE_KEYS_PRIME_DEPOSIT}/${depositId}`,
    { apiKey, secretKey }
  );

// только для Fortis и Crypto
export const appDepositRemove = (depositId, type) =>
  axiosInstance.delete(
    `${appEndpoints.USER_DEPOSITS.REMOVE_DEPOSIT}/${depositId}/${type}`
  );

export const appDepositStartAmountUpdate = (depositId, amount_start) =>
  axiosInstance.patch(
    `${appEndpoints.USER_DEPOSITS.UPDATE_DEPOSIT}/${depositId}`,
    { amount_start }
  );

export const appDepositStartDateUpdate = (depositId, start_date) =>
  axiosInstance.patch(
    `${appEndpoints.USER_DEPOSITS.UPDATE_DEPOSIT}/${depositId}`,
    { start_date }
  );

export const appDepositStatusUpdate = (depositId, status) =>
  axiosInstance.patch(
    `${appEndpoints.USER_DEPOSITS.UPDATE_DEPOSIT}/${depositId}`,
    { status }
  );

export const appDepositCurrentAmountUpdate = (depositId, amount_current) =>
  axiosInstance.patch(
    `${appEndpoints.USER_DEPOSITS.UPDATE_DEPOSIT}/${depositId}`,
    { amount_current }
  );

// только для Prime START
export const appPrimeDepositRemove = (depositId) =>
  axiosInstance.delete(
    `${appEndpoints.USER_DEPOSITS.REMOVE_PRIME_DEPOSIT}/${depositId}`
  );
export const appPrimeApprovalToggle = (depositId, readyToUse) =>
  axiosInstance.patch(
    `${appEndpoints.USER_DEPOSITS.TOGGLE_PRIME_APPROVAL}/${depositId}`,
    { readyToUse }
  );
// только для Prime END

export const appRemoveUser = (userId) =>
  axiosInstance.delete(`${appEndpoints.USER.REMOVE}/${userId}`);

// Рубильник для юзера, чтобы вкл/выкл доступ до FORTIS, CRYPTO
export const appProductsAccessUpdate = (userId) =>
  axiosInstance.get(`${appEndpoints.USER.UPDATE_ACCESS}/${userId}`);

export const appReportUpload = (userId, formData) =>
  axiosInstance.post(`${appEndpoints.USER.UPLOAD_REPORT}/${userId}`, formData);
