import React, { useState } from "react";
import AppAccordion from "../../../ui/AppAccordion";
import Typography from "@mui/material/Typography";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import {
  APPS,
  LS_MOB_APP_CURRENT_USER_ID_KEY,
} from "../../../utils/appConstants";
import UserDepositDocumentsChange from "./UserDepositDocumentsChange";
import UserDepositAmountChange from "./UserDepositAmountChange";
import UserDepositTitleChange from "./UserDepositTitleChange";
import UserDepositPercentChange from "./UserDepositPercentChange";
import UserDepositCommissionVisibilityChange from "./UserDepositCommissionVisibilityChange";
import UserDepositDateChange from "./UserDepositDateChange";
import UserDepositStatusChange from "./UserDepositStatusChange";
import UserDepositUpdateKeys from "./UserDepositUpdateKeys";
import UserDepositOnlySpotChange from "./UserDepositOnlySpotChange";

const UserDepositChanger = ({ deposit, title, contract_user, contractId }) => {
  const isPrime = deposit.type.toLowerCase() === APPS.PRIME.toLowerCase();

  const options = [
    { key: "title", value: "title", visibility: true },
    { key: "percent", value: "percent", visibility: !isPrime },
    { key: "contract", value: "contract", visibility: true },
    { key: "startAmount", value: "start amount", visibility: true },
    { key: "currentAmount", value: "current amount", visibility: !isPrime },
    { key: "status", value: "status", visibility: !isPrime },
    { key: "startDate", value: "start date", visibility: !isPrime },
    {
      key: "comVisibility",
      value: "toggle commission visibility",
      visibility: isPrime,
    },
    {
      key: "keys",
      value: "keys",
      visibility: isPrime,
    },
    {
      key: "is only spot",
      value: "is only spot",
      visibility: isPrime,
    },
  ];
  const [activeOption, setActiveOption] = useState("title");

  const handleActiveTabClick = (e) => setActiveOption(e.target.value);

  return (
    <AppAccordion
      titleJSX={
        <Typography variant="subtitle2">
          Change contract(status will not be changed)
        </Typography>
      }
    >
      <FormControl sx={{ marginBottom: 2 }} fullWidth>
        <InputLabel>Change</InputLabel>
        <Select
          value={activeOption}
          label="Change"
          onChange={handleActiveTabClick}
        >
          {options.map(
            (option) =>
              option.visibility && (
                <MenuItem size="small" value={option.value} key={option.key}>
                  {option.value}
                </MenuItem>
              )
          )}
        </Select>
      </FormControl>

      {activeOption === "title" && (
        <UserDepositTitleChange contractId={contractId} title={title} />
      )}
      {activeOption === "percent" && (
        <UserDepositPercentChange deposit={deposit} />
      )}
      {activeOption === "contract" && (
        <UserDepositDocumentsChange
          contract_user={contract_user}
          userId={localStorage.getItem(LS_MOB_APP_CURRENT_USER_ID_KEY)}
          contractId={contractId}
        />
      )}
      {activeOption === "start amount" && (
        <UserDepositAmountChange deposit={deposit} isStart={true} />
      )}
      {activeOption === "current amount" && !isPrime && (
        <UserDepositAmountChange deposit={deposit} isStart={false} />
      )}
      {activeOption === "start date" && !isPrime && (
        <UserDepositDateChange deposit={deposit} />
      )}
      {activeOption === "status" && !isPrime && (
        <UserDepositStatusChange deposit={deposit} />
      )}
      {activeOption === "toggle commission visibility" && isPrime && (
        <UserDepositCommissionVisibilityChange deposit={deposit} />
      )}
      {activeOption === "keys" && isPrime && (
        <UserDepositUpdateKeys deposit={deposit} />
      )}
      {activeOption === "is only spot" && isPrime && (
        <UserDepositOnlySpotChange deposit={deposit} />
      )}
    </AppAccordion>
  );
};

export default UserDepositChanger;
