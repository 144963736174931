import React, { useState } from "react";
import useUserHook from "../useUser.hook";
import toast, { Toaster } from "react-hot-toast";
import { appDepositStartDateUpdate } from "../../../api/user/appUser";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import AppDateInput from "../../../ui/AppDateInput";

const UserDepositDateChange = ({ deposit }) => {
  const [date, setDate] = useState(deposit.start_date ?? deposit.created_at);

  const handleAmountChange = (e) => {
    setDate(e);
  };

  const [inProgress, setInProgress] = useState(false);
  const { fetchUser } = useUserHook();
  const showErrorNotify = (text) => toast.error(text);

  const handleSaveChanges = async () => {
    setInProgress(true);
    console.log("date", date);
    await appDepositStartDateUpdate(deposit.id, date)
      .then(() => fetchUser())
      .catch((error) => showErrorNotify("Error the amount didn't change!"))
      .finally(() => setInProgress(false));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        rowGap: 1,
      }}
    >
      <AppDateInput
        sx={{ marginBottom: 1 }}
        value={date}
        handleChange={handleAmountChange}
      />
      <Button
        variant="contained"
        disabled={
          +date === +deposit.start_date ||
          +date === +deposit.created_at ||
          inProgress
        }
        onClick={handleSaveChanges}
      >
        Save changes
      </Button>

      <Toaster position="top-right" />
    </Box>
  );
};

export default UserDepositDateChange;
