import React, { useEffect, useState } from "react";
import {
  appDepositPercentOrCommissionChange,
  appGetAllDeposits,
  appGetIpAddress,
  appGetSettings,
  appToggleTechnicalWorks,
} from "../../api/settings/appSettings";
import Box from "@mui/material/Box";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import toast, { Toaster } from "react-hot-toast";
import { useRecoilState } from "recoil";
import { mobAppIsAuthorizedAtom } from "../../states/appIsAuthorized";
import LogoutIcon from "@mui/icons-material/Logout";
import { validateAsFloatNumber } from "../../../utils/validateString";
import { APPS } from "../../utils/appConstants";

const Ips = ({}) => {
  const [isLoggedIn, setLoggedIn] = useRecoilState(mobAppIsAuthorizedAtom);
  const options = [
    { key: "bybit", value: "BYBIT", visibility: true },
    { key: "binance", value: "BINANCE", visibility: true },
    { key: "kucoin", value: "KUCOIN", visibility: true },
  ];
  const [isFetching, setIsFetching] = useState(true);
  const [value, setValue] = useState("BINANCE");
  const [isFetchingError, setIsFetchingError] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const [ipAddress, setIpAddress] = useState("");

  const fetchIpAddress = async () => {
    setIsFetching(true);

    await appGetIpAddress(value)
      .then((response) => {
        setIpAddress(response.data.value);
        setIsFetchingError(false);
      })
      .catch((error) => {
        setIsFetchingError(true);
      })
      .finally(() => setIsFetching(false));
  };

  const onChangeBirge = async (e) => {
    setValue(e.target.value);
    setIsFetching(true);
    await appGetIpAddress(e.target.value)
      .then((response) => {
        setIpAddress(response.data.value);
        setIsFetchingError(false);
      })
      .catch((error) => {
        setIsFetchingError(true);
      })
      .finally(() => setIsFetching(false));
  };

  useEffect(() => {
    fetchIpAddress();
  }, []);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(ipAddress);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Сбросить состояние через 2 секунды
    } catch (err) {
      console.error("Ошибка при копировании: ", err);
    }
  };

  return (
    <>
      {isFetchingError ? (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100dvh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h5" sx={{ margin: 2 }}>
            Error! Failed to load page, please try later.
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            rowGap: 2,
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              columnGap: 1,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                justifyContent: "center",
                rowGap: 1,
                // width: "100%",
              }}
            >
              <span>Choose an exchange</span>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Select
                  defaultValue={"BINANCE"}
                  onChange={onChangeBirge}
                  sx={{ width: "170px" }}
                >
                  {options.map(
                    (option) =>
                      option.visibility && (
                        <MenuItem
                          size="small"
                          value={option.value}
                          key={option.key}
                        >
                          {option.value}
                        </MenuItem>
                      )
                  )}
                </Select>
                {!!ipAddress ? (
                  <Button
                    type="contained"
                    disabled={isFetching}
                    onClick={handleCopy}
                  >
                    Copy IP Address
                  </Button>
                ) : (
                  <span>No IP address</span>
                )}
              </Box>
            </Box>
            <Button
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 1,
                marginLeft: "auto",
              }}
              variant="contained"
              onClick={() => setLoggedIn(false)}
            >
              <LogoutIcon />
              LOG OUT from TSFC APP
            </Button>
          </Box>

          <Backdrop open={isFetching} sx={{ color: "#fff" }}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <Toaster position="top-right" />
        </Box>
      )}
    </>
  );
};

export default Ips;
