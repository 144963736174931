import React, { useState } from "react";
import useUserHook from "../useUser.hook";
import toast, { Toaster } from "react-hot-toast";
import { appPrimeKeysUpdate } from "../../../api/user/appUser";
import Box from "@mui/material/Box";
import AppInput from "../../../ui/AppInput";
import { Button } from "@mui/material";

const UserDepositUpdateKeys = ({ deposit }) => {
  const [apiKey, setApiKey] = useState("");
  const [secretKey, setSecretKey] = useState("");

  const handleApiKeyChange = (e) => {
    setApiKey(e.target.value);
  };

  const handleSecretKeyChange = (e) => {
    setSecretKey(e.target.value);
  };

  const [inProgress, setInProgress] = useState(false);
  const { fetchUser } = useUserHook();
  const showErrorNotify = (text) => toast.error(text);

  const handleSaveChanges = async () => {
    setInProgress(true);

    await appPrimeKeysUpdate(deposit.id, apiKey, secretKey)
      .then(() => fetchUser())
      .catch((error) => showErrorNotify("Error the amount didn't change!"))
      .finally(() => setInProgress(false));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        rowGap: 1,
      }}
    >
      <AppInput
        sx={{ marginBottom: 1 }}
        value={apiKey}
        label="Api key"
        handleChange={handleApiKeyChange}
      />
      <AppInput
        sx={{ marginBottom: 1 }}
        value={secretKey}
        label="Secret key"
        handleChange={handleSecretKeyChange}
      />
      <Button
        variant="contained"
        disabled={inProgress}
        onClick={handleSaveChanges}
      >
        Save changes
      </Button>

      <Toaster position="top-right" />
    </Box>
  );
};

export default UserDepositUpdateKeys;
