import React, { useState } from "react";
import useUserHook from "../useUser.hook";
import toast, { Toaster } from "react-hot-toast";
import {
  appDepositStartDateUpdate,
  appDepositStatusUpdate,
} from "../../../api/user/appUser";
import Box from "@mui/material/Box";
import { Button, FormControl, InputLabel, MenuItem } from "@mui/material";
import AppDateInput from "../../../ui/AppDateInput";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import AppAccordion from "../../../ui/AppAccordion";

const UserDepositStatusChange = ({ deposit }) => {
  const options = [
    { key: "ReCreation", value: "Re-creation", visibility: true },
    { key: "Created", value: "Created", visibility: true },
    { key: "Updated", value: "Updated", visibility: true },
    { key: "NotPayment", value: "Not payment", visibility: true },
    {
      key: "DocumentPreparation",
      value: "Document preparation",
      visibility: true,
    },
    { key: "DocumentSigning", value: "Document signing", visibility: true },
    {
      key: "DocumentPreparationReOpen",
      value: "Document preparation re-open",
      visibility: true,
    },
    {
      key: "DocumentSigningReOpen",
      value: "Document signing re-open",
      visibility: true,
    },
    {
      key: "DocumentPreparationUpdated",
      value: "Document preparation updated",
      visibility: true,
    },
    {
      key: "DocumentSigningUpdated",
      value: "Document signing updated",
      visibility: true,
    },
    { key: "Active", value: "Active", visibility: true },
    { key: "Completed", value: "Completed", visibility: true },
    { key: "Paid", value: "Paid", visibility: true },
  ];
  const [activeOption, setActiveOption] = useState(deposit.status);

  const handleActiveTabClick = (e) => setActiveOption(e.target.value);

  const [inProgress, setInProgress] = useState(false);
  const { fetchUser } = useUserHook();
  const showErrorNotify = (text) => toast.error(text);

  const handleSaveChanges = async () => {
    setInProgress(true);

    await appDepositStatusUpdate(deposit.id, activeOption)
      .then(() => fetchUser())
      .catch((error) => showErrorNotify("Error the amount didn't change!"))
      .finally(() => setInProgress(false));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        rowGap: 1,
      }}
    >
      <Select value={activeOption} label="Type" onChange={handleActiveTabClick}>
        {options.map(
          (option) =>
            option.visibility && (
              <MenuItem size="small" value={option.value} key={option.key}>
                {option.value}
              </MenuItem>
            )
        )}
      </Select>
      <Button
        variant="contained"
        disabled={inProgress}
        onClick={handleSaveChanges}
      >
        Save changes
      </Button>

      <Toaster position="top-right" />
    </Box>
  );
};

export default UserDepositStatusChange;
