import { useState } from "react";
import {
  Collapse,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MobileOffIcon from "@mui/icons-material/MobileOff";
import {
  Code,
  CurrencyBitcoin,
  ExpandLess,
  ExpandMore,
  MonetizationOn,
  Person,
  Public,
  Settings,
  VpnKey,
} from "@mui/icons-material";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import GroupIcon from "@mui/icons-material/Group";
import { useRecoilState } from "recoil";
import { currentTabAtom } from "../states";
import { mobAppViewAtom } from "../tsfc-mobile-application/states/appViews";
import { views } from "../tsfc-mobile-application/utils/appConstants";
import { mobAppIsAuthorizedAtom } from "../tsfc-mobile-application/states/appIsAuthorized";
import TuneIcon from "@mui/icons-material/Tune";
import PersonIcon from "@mui/icons-material/Person";
import PaymentIcon from "@mui/icons-material/Payment";
import NotificationsIcon from "@mui/icons-material/Notifications";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

const Sidebar = () => {
  const [currentTab, setCurrentTab] = useRecoilState(currentTabAtom);

  // для переключения страниц во вкладке TSFC Application
  const [currentView, setCurrentView] = useRecoilState(mobAppViewAtom);
  const [isMobAppAuthorized] = useRecoilState(mobAppIsAuthorizedAtom);

  const [mobAppIsOpen, setMobAppIsOpen] = useState(true);

  const mobAppButtons = [
    { icon: <GroupIcon />, name: "Users", link: views.users },
    { icon: <PersonIcon />, name: "User", link: views.user },
    { icon: <PaymentIcon />, name: "Finance", link: views.finance },
    {
      icon: <NotificationsIcon />,
      name: "Notifications",
      link: views.notifications,
    },
    { icon: <VpnKeyIcon />, name: "Keys", link: views.keys },
    { icon: <TuneIcon />, name: "Settings", link: views.settings },
    { icon: <Icon />, name: "IPs", link: views.ips },
  ];

  const handleTSFCApp = () => {
    currentTab !== 7 && setCurrentTab(7);
    isMobAppAuthorized &&
      currentTab === 7 &&
      setMobAppIsOpen((prevState) => !prevState);
  };
  const handleTSFCAppInner = (viewLink) => {
    currentTab !== 7 && setCurrentTab(7);
    setCurrentView(viewLink);
  };

  return (
    <List sx={{ marginTop: "64px" }}>
      <ListItem disablePadding>
        <ListItemButton
          selected={currentTab === 0}
          onClick={() => setCurrentTab(0)}
        >
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          selected={currentTab === 1}
          onClick={() => setCurrentTab(1)}
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="User" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          selected={currentTab === 2}
          onClick={() => setCurrentTab(2)}
        >
          <ListItemIcon>
            <PaymentIcon />
          </ListItemIcon>
          <ListItemText primary="Finance" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          selected={currentTab === 3}
          onClick={() => setCurrentTab(3)}
        >
          <ListItemIcon>
            <NotificationsIcon />
          </ListItemIcon>
          <ListItemText primary="Notifications" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          selected={currentTab === 4}
          onClick={() => setCurrentTab(4)}
        >
          <ListItemIcon>
            <VpnKeyIcon />
          </ListItemIcon>
          <ListItemText primary="Keys" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          selected={currentTab === 5}
          onClick={() => setCurrentTab(5)}
        >
          <ListItemIcon>
            <TuneIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          selected={currentTab === 6}
          onClick={() => setCurrentTab(6)}
        >
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText primary="IPs" />
        </ListItemButton>
      </ListItem>
      {/*<ListItem disablePadding>*/}
      {/*  <ListItemButton selected={currentTab === 6} onClick={handleTSFCApp}>*/}
      {/*    <ListItemIcon>*/}
      {/*      {isMobAppAuthorized ? (*/}
      {/*        <StayCurrentPortraitIcon />*/}
      {/*      ) : (*/}
      {/*        <MobileOffIcon />*/}
      {/*      )}*/}
      {/*    </ListItemIcon>*/}
      {/*    <ListItemText primary="TSFC App" />*/}
      {/*    {isMobAppAuthorized &&*/}
      {/*      (mobAppIsOpen ? <ExpandLess /> : <ExpandMore />)}*/}
      {/*  </ListItemButton>*/}
      {/*</ListItem>*/}
      {/*<Collapse*/}
      {/*  in={isMobAppAuthorized && mobAppIsOpen}*/}
      {/*  timeout="auto"*/}
      {/*  unmountOnExit*/}
      {/*>*/}
      {/*  <List component="div" disablePadding>*/}
      {/*    {mobAppButtons.map(button => (*/}
      {/*      <ListItemButton*/}
      {/*        key={button.name}*/}
      {/*        sx={{ pl: 4 }}*/}
      {/*        selected={currentTab === 6 && currentView === button.link}*/}
      {/*        onClick={() => handleTSFCAppInner(button.link)}*/}
      {/*      >*/}
      {/*        <ListItemIcon>{button.icon}</ListItemIcon>*/}
      {/*        <ListItemText primary={button.name} />*/}
      {/*      </ListItemButton>*/}
      {/*    ))}*/}
      {/*  </List>*/}
      {/*</Collapse>*/}

      {/*<ListItem disablePadding>*/}
      {/*  <ListItemButton*/}
      {/*    selected={currentTab === 5}*/}
      {/*    onClick={() => setCurrentTab(5)}*/}
      {/*  >*/}
      {/*    <ListItemIcon>*/}
      {/*      <Badge />*/}
      {/*    </ListItemIcon>*/}
      {/*    <ListItemText primary="Verified" />*/}
      {/*  </ListItemButton>*/}
      {/*</ListItem>*/}
      {/*<ListItem disablePadding>*/}
      {/*  <ListItemButton*/}
      {/*    selected={currentTab === 1}*/}
      {/*    onClick={() => setCurrentTab(1)}*/}
      {/*  >*/}
      {/*    <ListItemIcon>*/}
      {/*      <MuiBadge badgeContent={unverified.length} color={"success"}>*/}
      {/*        <Person />*/}
      {/*      </MuiBadge>*/}
      {/*    </ListItemIcon>*/}
      {/*    <ListItemText primary="Unverified" />*/}
      {/*  </ListItemButton>*/}
      {/*</ListItem>*/}
      {/*<ListItem disablePadding>*/}
      {/*  <ListItemButton*/}
      {/*    selected={currentTab === 2}*/}
      {/*    onClick={() => setCurrentTab(2)}*/}
      {/*  >*/}
      {/*    <ListItemIcon>*/}
      {/*      <PersonAddAlt1 />*/}
      {/*    </ListItemIcon>*/}
      {/*    <ListItemText primary="All users" />*/}
      {/*  </ListItemButton>*/}
      {/*</ListItem>*/}

      {/*<ListItem disablePadding>*/}
      {/*  <ListItemButton*/}
      {/*    selected={currentTab === 4}*/}
      {/*    onClick={() => setCurrentTab(4)}*/}
      {/*  >*/}
      {/*    <ListItemIcon>*/}
      {/*      <Contrast />*/}
      {/*    </ListItemIcon>*/}
      {/*    <ListItemText primary="CryptoSafe" />*/}
      {/*  </ListItemButton>*/}
      {/*</ListItem>*/}

      {/*<ListItem disablePadding>*/}
      {/*  <ListItemButton*/}
      {/*    selected={currentTab === 5}*/}
      {/*    onClick={() => setCurrentTab(5)}*/}
      {/*  >*/}
      {/*    <ListItemIcon>*/}
      {/*      <DocumentScanner />*/}
      {/*    </ListItemIcon>*/}
      {/*    <ListItemText primary="MaxiProfit" />*/}
      {/*  </ListItemButton>*/}
      {/*</ListItem>*/}
    </List>
  );
};

export default Sidebar;
